import { $App } from ".."

export default class {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createForm()
  }

  createForm() {
    const form = document.querySelector<HTMLFormElement>(
      ".__contact .contact form"
    )
    const btn = document.querySelector<HTMLButtonElement>(
      ".__contact .contact form button"
    )

    form.onsubmit = async (e) => {
      e.preventDefault()
      btn.classList.add("active")
      const formData = new FormData(form)
      var object = {}
      formData.forEach((value, key) => (object[key] = value))
      var json = JSON.stringify(object)
      try {
        const api = await fetch("/contact", {
          method: "POST",
          body: json,
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (api.ok) {
          btn.classList.remove("active")
          btn.innerHTML = `MESSAGE SENT <img src="/images/loading.svg"/>`
          form.reset()
        }
      } catch {
        btn.classList.remove("active")
        btn.innerHTML = `RETRY <img src="/images/loading.svg"/>`
      } finally {
      }
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
