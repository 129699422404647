import { Renderer, Camera, Transform, Plane } from "ogl"

export default class GL {
  renderer: Renderer
  gl: any
  camera: Camera
  scene: Transform
  screen: { height: number; width: number }
  viewport: { height: number; width: number }
  planeGeometry: Plane
  constructor({ element }: { element: string }) {
    this.createRenderer(element)
    this.createCamera()
    this.createScene()

    this.onResize()

    this.update()

    this.addEventListeners()
  }

  createRenderer(element: string) {
    this.renderer = new Renderer({
      alpha: true,
      antialias: true,
    })

    this.gl = this.renderer.gl

    document.querySelector(element).appendChild(this.gl.canvas)
  }

  createCamera() {
    this.camera = new Camera(this.gl)
    this.camera.fov = 45
    this.camera.position.z = 5
  }

  createScene() {
    this.scene = new Transform()
  }

  createGeometry() {
    this.planeGeometry = new Plane(this.gl, {
      widthSegments: 32,
      heightSegments: 32,
    })
  }

  /**
   * Wheel.
   */
  onWheel(event) {}

  /**
   * Resize.
   */
  onResize() {
    this.screen = {
      height: this.renderer.gl.canvas.parentElement.clientHeight,
      width: this.renderer.gl.canvas.parentElement.clientWidth,
    }

    this.renderer.setSize(this.screen.width, this.screen.height)

    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height,
    })

    const fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect

    this.viewport = {
      height,
      width,
    }
  }

  /**
   * Update.
   */
  update() {
    this.renderer.render({
      scene: this.scene,
      camera: this.camera,
    })

    window.requestAnimationFrame(this.update.bind(this))
  }

  /**
   * Listeners.
   */
  addEventListeners() {
    window.addEventListener("resize", this.onResize.bind(this))

    window.addEventListener("mousewheel", this.onWheel.bind(this))
    window.addEventListener("wheel", this.onWheel.bind(this))
  }
}
