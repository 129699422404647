import { $App } from ".."

export default class {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createForm()
  }

  async createForm() {
    const form = document.querySelector<HTMLFormElement>(".__blog form")

    form.onsubmit = async (e) => {
      e.preventDefault()
      const url = "/join"
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: form.querySelector<HTMLInputElement>("input[type='email']")
            .value,
          send_welcome_email: true,
          utm_medium: "organic",
          referring_site: "www.thegarage.ng/blog",
          custom_fields: [
            {
              name: "First Name",
              value:
                form.querySelector<HTMLInputElement>("input[type='text']")
                  .value,
            },
          ],
        }),
      }

      try {
        const response = await fetch(url, options)
        const data = await response.json()
        form.reset()
        form.classList.add("active")
        form.querySelector("button").innerText = "You're in!"
      } catch (error) {
        // console.error(error)
        form.reset()
      }
    }
  }

  resize() {}

  destroy() {}

  navigate() {}
}
