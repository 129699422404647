import { Plane, Transform, Mesh, Program, Texture, Renderer, Camera } from "ogl"
import OGL from "./GL"
import gsap from "gsap"

import fragment, { fragment2 } from "../shaders/fragment"
import vertex from "../shaders/vertex"

export default class Media {
  element: Element
  image: HTMLImageElement
  geometry: Plane
  gl: OGL
  scene: Transform
  screen: { height: number; width: number }
  viewport: { height: number; width: number }
  plane: Mesh
  bounds: DOMRect
  parent: any
  boundsParent: DOMRect
  constructor({
    element,
    geometry,
    gl,
    scene,
    screen,
    viewport,
  }: {
    element: Element
    geometry: Plane
    gl: OGL
    scene: Transform
    screen: { height: number; width: number }
    viewport: { height: number; width: number }
  }) {
    this.element = element
    this.image = this.element.querySelector("img")

    this.geometry = geometry
    this.gl = gl
    this.scene = scene
    this.screen = screen
    this.viewport = viewport

    this.createMesh()
    this.createBounds()
    this.createPointer()

    this.onResize()
  }

  createPointer() {
    const el = this.element as unknown as HTMLDivElement
    const xTo = gsap.quickTo(this.parent.rotation, "x", {
      duration: 0.4,
      ease: "power3",
    })
    const yTo = gsap.quickTo(this.parent.rotation, "y", {
      duration: 0.4,
      ease: "power3",
    })
    el.onpointermove = (e) => {
      const y = (e.offsetX / el.clientWidth - 0.5) * 2
      const x = (e.offsetY / el.clientHeight - 0.5) * 2

      xTo((y * 7.5 * -Math.PI) / 180)
      yTo((x * 7.5 * -Math.PI) / 180)
      // this.updateRotationY(y)
      // this.updateRotationX(x)
    }
  }

  updateRotationY(y: number) {
    this.parent.rotation.y = (y * 5 * -Math.PI) / 180
  }

  updateRotationX(x: number) {
    this.parent.rotation.x = (x * 5 * -Math.PI) / 180
  }

  createMesh() {
    const image = new Image()
    const texture = new Texture(this.gl.renderer.gl)

    image.src = this.image.src
    image.onload = (_) => {
      program.uniforms.uImageSizes.value = [
        image.naturalWidth,
        image.naturalHeight,
      ]
      texture.image = image
    }

    const program = new Program(this.gl.renderer.gl, {
      fragment,
      vertex,
      uniforms: {
        tMap: { value: texture },
        uPlaneSizes: { value: [0, 0] },
        uImageSizes: { value: [0, 0] },
      },
      transparent: true,
    })

    const program2 = new Program(this.gl.renderer.gl, {
      fragment: fragment2,
      vertex,
      uniforms: {
        tMap: { value: texture },
        uPlaneSizes: { value: [0, 0] },
        uImageSizes: { value: [0, 0] },
      },
      transparent: true,
    })

    this.plane = new Mesh(this.gl.renderer.gl, {
      geometry: this.geometry,
      program,
    })

    this.parent = new Mesh(this.gl.renderer.gl, {
      geometry: this.geometry,
      program: program2,
    })

    this.parent.setParent(this.scene)
    this.plane.setParent(this.parent)
    // this.scene.position.z -= 2
    this.plane.position.z += 0.001
  }

  createBounds() {
    this.bounds = this.image.getBoundingClientRect()
    this.boundsParent =
      this.image.parentElement.parentElement.getBoundingClientRect()

    this.updateScale()
    this.updateRotation()
    this.updateX()
    this.updateY()

    this.plane.program.uniforms.uPlaneSizes.value = [
      this.bounds.height / this.boundsParent.height,
      this.bounds.width / this.boundsParent.width,
    ]
  }

  updateScale() {
    this.plane.scale.x = this.bounds.width / this.boundsParent.width
    this.plane.scale.y = this.bounds.height / this.boundsParent.height

    this.parent.scale.x =
      (this.viewport.width * this.boundsParent.width) / this.screen.width
    this.parent.scale.y =
      (this.viewport.height * this.boundsParent.height) / this.screen.height
  }

  updateRotation() {
    this.parent.rotation.z =
      (Number(this.image.getAttribute("data-rotate")) * -Math.PI) / 180
  }

  updateX(x = 0) {
    this.plane.position.x = 0

    this.parent.position.x =
      -(this.viewport.width / 2) +
      this.parent.scale.x / 2 +
      ((this.boundsParent.left - x) / this.screen.width) * this.viewport.width
  }

  updateY(y = 0) {
    this.plane.position.y = 0.075
    //   this.viewport.height / 2 -
    //   this.plane.scale.y / 2 -
    //   ((this.bounds.top -
    //     this.gl.renderer.gl.canvas.parentElement.offsetTop -
    //     y) /
    //     this.screen.height) *
    //     this.viewport.height

    this.parent.position.y =
      this.viewport.height / 2 -
      this.parent.scale.y / 2 -
      ((this.boundsParent.top -
        this.gl.renderer.gl.canvas.parentElement.offsetTop -
        y) /
        this.screen.height) *
        this.viewport.height
  }

  update(y?: number) {
    this.updateScale()
    this.updateX()
    this.updateY(y)
  }

  onResize(sizes?: {
    screen: { height: number; width: number }
    viewport: { height: number; width: number }
  }) {
    if (sizes) {
      const { screen, viewport } = sizes

      if (screen) this.screen = screen
      if (viewport) this.viewport = viewport
    }

    this.createBounds()
  }
}
